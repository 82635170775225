import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
const ErrorPage = () => {
  return (
    <Layout>
      <Seo title={"Digital System DA - Pagina no encontrada"} />
        <div className='h-screen flex justify-center items-center'>
            <div className='w-11/12 mx-auto'>
                <h2 className='font-bold text-3xl md:text-5xl md:w-1/2 text-center mx-auto'>¡Ups! <span className='block mt-10'>Parece que la página que buscas no existe</span></h2>
                <Link to="/" className="bg-primary mt-20 w-fit mx-auto text-secondary rounded-3xl py-2 px-5 block transition-colors hover:bg-btn1-hover">Volver al inicio</Link>
            
            </div>
        </div>

    </Layout>
  )
}

export default ErrorPage